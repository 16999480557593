import "./register-page.css";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { Footer, Header } from "../../layouts";
import AsyncTypeahead from "react-bootstrap-typeahead/types/components/AsyncTypeahead/AsyncTypeahead";
import PropertyLoanApiService from "../../data/api/services/property-loan/property-loan-api-service";
import PropertyApiService from "../../data/api/services/property/property-api-service";
import { SignupPayload } from "../../data/api/services/register/signup-payload";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import RegisterApiService from "../../data/api/services/register/register-api-service";
import ApiConfig from "../../data/api/api-config";
import GoogleLogo from '../../assets/google-logo.png';
import FacebookLogo from '../../assets/facebook-logo.png';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col } from "react-bootstrap";
import AuthApiService from "../../data/api/services/auth/auth-api-service";
import SmsApiService from "../../data/api/services/sms/sms-api-service";

const RegisterPage = ({ updatePayload, onNextCallback }) => {

    const authService: AuthApiService = new AuthApiService();
    const smsService: SmsApiService = new SmsApiService();
    const [firstName, setFirstName] = useState("");
    const [firstNameValidationError, setFirstNameValidationError] = useState(false);
    const [lastName, setLastName] = useState("");
    const [lastNameValidationError, setLastNameValidationError] = useState(false);
    const [email, setEmail] = useState("");
    const [emailValidationError, setEmailValidationError] = useState(false);
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    const [passwordValidationError, setPasswordValidationError] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordValidationError, setConfirmPasswordValidationError] = useState(false);
    const [userType, setUserType] = useState("");
    // const [locationData, setLocationData] = useState<any>([]);
    // const [locationSearchLoading, setLocationSearchLoading] = useState(false);
    // const [selectedLocation, setSelectedLocation] = useState<any>([]);
    // const [locationValidationError, setLocationValidationError] = useState(false);
    const [countryValidationError, setCountryValidationError] = useState(false);
    const [countryData, setCountryData] = useState<any>([]);
    const [countryCode, setCountryCode] = useState("");
    const [mobileValidationError, setMobileNoValidationError] = useState(false);
    const [generatedOtp, setGeneratedOtp] = useState("");
    const tabNumber: number = 1;
    const propertyLoanApiService = new PropertyLoanApiService();
    const propertyApiService = new PropertyApiService();
    const apiConfig = new ApiConfig();
    const [baseUrl, setBaseUrl] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        getTemplatesData();
        setBaseUrl(apiConfig.getBaseURL);
    }, []);

    const handleFirstNameChange = (value: any) => {
        setFirstName(value);
        setFirstNameValidationError(false);
    };

    const handleLastNameChange = (value: any) => {
        setLastName(value);
        setLastNameValidationError(false);
    };

    const handleEmailChange = (value: any) => {
        setEmail(value);
        setEmailValidationError(false);
    };

    const handlePasswordChange = (value: any) => {
        setPassword(value);
        setPasswordValidationError(false);
    };

    const handleConfirmPasswordChange = (value: any) => {
        setConfirmPassword(value);
        setConfirmPasswordValidationError(false);
    };

    const handleMobileNumberChange = (value: any) => {
        const formattedValue = value.replace(/\D/g, '').slice(0, 10);
        setMobile(formattedValue);
        setMobileNoValidationError(false);
    };

    const constructPayload = (otp) => {
        validateRegistrationForm();
        let payload: SignupPayload = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            mobile: mobile,
            password: password,
            userType: userType,
            locationId: "",
            otp: otp,
            membershipBenefitMapperId: "",
            amount: "",
            promoCode: "",
            logInType: "NORMAL",
            professionTypeId: ""
        };
        updatePayload(payload);
        setLoading(false);
        onNextCallback((tabNumber + 1));
        toast.success("Please Wait OTP will received to your mobile no. ", { containerId: 'TR' });
    }

    const generateOtp = async () => {
        try {
            let data = await smsService.generateOtp(mobile);
            setGeneratedOtp(data.content);
            console.log(data.content);
            if (data.status == "Success") {
                constructPayload(data.content);
            } else {
                setLoading(false);
                toast.error("Try Again...", { containerId: 'TR' });
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error(error.response.data.message, { containerId: 'TR' });
        }
    };

    const VerifyOtp = async () => {
        try {
            if (validateMobile()) {
                setLoading(true);
                checkUser();
            }
        } catch (error) {
            console.log(error)
            if (error.response.data.message) {
                toast.error(error.response.data.message, { containerId: 'TR' });
            }
        }
    };

    const checkUser = async () => {
        try {
            let url = `?mobileNo=${mobile}&email=${email}&logInType=${"NORMAL"}`;
            let data = await authService.fetchUserByMobileNoEmailLogInTypeAndUserType(url);
            console.log('dataaaaaa=>', data);
            if (!data) {
                generateOtp();
            } else {
                setLoading(false);
                toast.error("Mobile or Email Already Exist ", { containerId: 'TR' });
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const validateMobile = () => {
        let isFormValid = true;
        if (!firstName) {
            setFirstNameValidationError(true);
            isFormValid = false;
        }
        if (!lastName) {
            setLastNameValidationError(true);
            isFormValid = false;
        }
        if (!email) {
            setEmailValidationError(true);
            isFormValid = false;
        }
        if (!password) {
            setPasswordValidationError(true);
            isFormValid = false;
        }
        if (!confirmPassword) {
            setConfirmPasswordValidationError(true);
            isFormValid = false;
        }
        if (password != confirmPassword) {
            toast.error("Password does not match", { containerId: 'TR' });
            isFormValid = false;
        }
        // if (selectedLocation.length == 0) {
        //     setLocationValidationError(true);
        //     isFormValid = false;
        // }
        if (!countryCode) {
            setCountryValidationError(true);
            isFormValid = false;
        }
        if (!mobile) {
            setMobileNoValidationError(true);
            isFormValid = false;
        }
        if (mobile) {
            if (mobile.length <= 9) {
                toast.error("Enter a Valid Mobile No. ", { containerId: 'TR' });
                isFormValid = false;
            }
        }
        return isFormValid;
    };

    const validateRegistrationForm = () => {
        // if (selectedLocation.length == 0) {
        //     toast.error("Location required", { containerId: 'TR' });
        //     return;
        // }
        if (password != confirmPassword) {
            toast.error("Password does not match", { containerId: 'TR' });
            return;
        }
        if (!mobile) {
            toast.error("Mobile No. required", { containerId: 'TR' });
            return;
        }
        if (mobile) {
            if (mobile.length <= 9) {
                toast.error("Enter a Valid Mobile No. ", { containerId: 'TR' });
            }
        }
        if (!countryCode) {
            toast.error("Country Code required", { containerId: 'TR' });
            return;
        }
    };

    const handleCountryChange = (code: any) => {
        setCountryCode(code);
        setCountryValidationError(false);
    };

    const getTemplatesData = async () => {
        try {
            let res = await propertyLoanApiService.getPropertyLoanTemplatesData();
            console.log(res);
            setCountryData(res.countryData);
            let initialCountryCode: any;
            if (res.countryData && res.countryData.length > 0) {
                initialCountryCode = res.countryData[0].countryCode;
                setCountryCode(initialCountryCode);
            }
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    };

    const googleLogin = () => {
        window.location.href = baseUrl + '/oauth2/authorization/google';
    };

    const facebookLogin = () => {
        window.location.href = baseUrl + '/oauth2/authorization/facebook';
    };

    return <>
        {/* <Modal size="lg" show={showPopUp} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Verify Your Promo Code</Modal.Title>
            </Modal.Header>
            <div >
                <div className="modal-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-2 mt-2">
                                    <label>Promo Code :</label>
                                </div>
                                <div className="col-sm-4">
                                    <input type="text" className={`input-value form-control ${promoCodeValidationError ? 'is-invalid' : ''}`} value={promoCodeText} onChange={(e) => { handlePromoCodeChange(e.target.value) }}
                                        placeholder="Enter your code" name="firstName" />                                                 <i className="ti-user"></i>
                                    {promoCodeValidationError && <div className="invalid-feedback"> Promo code required</div>}
                                </div>
                                <div className="col-sm-3 mt-1">
                                    {!verifyCodeProcess && <button className="btn btn-success" type="button"
                                        onClick={getPromoCodeByCode}> verify</button>}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {verifyCodeProcess && <div className="modal-body">
                <div className="row">
                    <div className="row">
                        <div className="col-sm-2 mt-2">
                            <label>You will get </label>
                        </div>
                        <div className="col-sm-3">
                            <label>{promoCode.discountAmount}</label>
                        </div>
                        <div className="col-sm-3 mt-1">
                            <button className="btn btn-success" type="button" onClick={applyPromoCode}>apply</button>
                        </div>
                    </div>
                </div>
            </div>}

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal> */}
        <div id="app">
            <Header></Header>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9 mt-3">
                            <div id="sign-up" className="modal-content">
                                <div className="modal-body">
                                    <h2 className="text-center">Register</h2>
                                    <br />
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <div>
                                                    <input type="text" className={`input-with-icon form-control p-3 ${firstNameValidationError ? 'is-invalid' : ''}`}
                                                        style={{ backgroundImage: 'none' }}
                                                        value={firstName} onChange={(e) => { handleFirstNameChange(e.target.value) }}
                                                        placeholder="First Name" name="firstName" />
                                                    <i className="ti-user"></i>
                                                    {firstNameValidationError && <div className="invalid-feedback">First Name required</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <div>
                                                    <input type="text" className={`input-with-icon form-control p-3 ${lastNameValidationError ? 'is-invalid' : ''}`}
                                                        style={{ backgroundImage: 'none' }}
                                                        value={lastName} onChange={e => { handleLastNameChange(e.target.value) }} placeholder="Last name" name="lastName" />
                                                    <i className="ti-user"></i>
                                                    {lastNameValidationError && <div className="invalid-feedback">Last Name required</div>}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <Col className="justify-content-end align-items-center d-flex async-container">
                                                    <Col xs="auto" className="close-icon">
                                                        {selectedLocation.length > 0 && (
                                                            <FontAwesomeIcon icon={faTimes} onClick={clearSelectedLocation} />
                                                        )}
                                                    </Col>
                                                    <Col>
                                                        <AsyncTypeahead
                                                            className={`form-control p-0 ${locationValidationError ? 'is-invalid' : ''}`}
                                                            style={{ height: '56px', backgroundImage: 'none', width: 'auto' }}
                                                            id="basic-typeahead-single"
                                                            labelKey="formatedLocationData"
                                                            onSearch={(e) => searchLocation(e)}
                                                            options={locationData}
                                                            onChange={onLocationSelected}
                                                            useCache={false}
                                                            placeholder="Location"
                                                            defaultSelected={selectedLocation}
                                                            isLoading={locationSearchLoading}
                                                            selected={selectedLocation}
                                                            renderMenuItemChildren={renderMenuItemChildren}
                                                        />
                                                        {locationValidationError && <div className="invalid-feedback">Location required</div>}
                                                    </Col>
                                                </Col>
                                            </div>
                                        </div> */}

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <div>
                                                    <input type="password" className={`input-with-icon form-control p-3 ${passwordValidationError ? 'is-invalid' : ''}`}
                                                        style={{ backgroundImage: 'none' }}
                                                        value={password} onChange={e => { handlePasswordChange(e.target.value) }} placeholder="Password" name="password" />
                                                    <i className="ti-unlock"></i>
                                                    {passwordValidationError && <div className="invalid-feedback">Password required</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <div>
                                                    <input type="password" className={`input-with-icon form-control p-3 ${confirmPasswordValidationError ? 'is-invalid' : ''}`}
                                                        style={{ backgroundImage: 'none' }}
                                                        value={confirmPassword} onChange={e => { handleConfirmPasswordChange(e.target.value) }} placeholder="Confirm Password" name="password_confirmation" />
                                                    <i className="ti-unlock"></i>
                                                    {confirmPasswordValidationError && <div className="invalid-feedback">Confirm Password required</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="row">
                                                <div className="form-group col-sm-3">
                                                    <select className={`input-with-icon form-control p-3 ${countryValidationError ? 'is-invalid' : ''}`}
                                                        style={{ backgroundImage: 'none' }}
                                                        value={countryCode}
                                                        onChange={(e) => handleCountryChange(e.target.value)} placeholder="select" >
                                                        <option value="">{countryData && countryData[0] ? countryData[0].countryCode : 'Select'}</option>
                                                        {countryData.map((cData: any, index: any) => (
                                                            <option key={cData.id} value={cData.countryCode}>{cData.countryCode}</option>
                                                        ))}
                                                    </select>
                                                    {countryValidationError && <div className="invalid-feedback">Country code required</div>}
                                                </div>
                                                <div className="form-group col-sm-9">
                                                    <div >
                                                        <input className={`input-with-icon form-control p-3 ${mobileValidationError ? 'is-invalid' : ''}`} type="number"
                                                            style={{ backgroundImage: 'none' }}
                                                            value={mobile} onChange={e => { handleMobileNumberChange(e.target.value) }} placeholder="Phone/ WhatsApp number" name="password_confirmation" />
                                                        <i className="ti-unlock"></i>
                                                        {mobileValidationError && <div className="invalid-feedback">Mobile No. required</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <div>
                                                    <input type="email" className={`input-with-icon form-control p-3 ${emailValidationError ? 'is-invalid' : ''}`}
                                                        style={{ backgroundImage: 'none' }}
                                                        value={email} onChange={e => { handleEmailChange(e.target.value) }} placeholder="Email" name="email" />
                                                    <i className="ti-email"></i>
                                                    {emailValidationError && <div className="invalid-feedback">Email required</div>}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-12 col-md-12">
                                            <div className="form-group text-center mt-3">
                                                {!showCodeAppliedText && <p>Have any PromoCode ? <a href="#" className="link d-block d-sm-inline-block text-sm-left text-danger text-center" onClick={openDialog}> apply</a>
                                                </p>}
                                                {showCodeAppliedText && <p className="green-text">Promo Code Applied !</p>}
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="form-group" style={{ position: 'relative' }}>
                                        {loading && (
                                            <div className="d-flex justify-content-center align-items-center"
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                    zIndex: 2
                                                }}>
                                                <div className="loading">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </div>
                                        )}
                                        <button type="button" className="btn btn-md full-width btn-theme-light-2 rounded" onClick={VerifyOtp}>
                                            Verify OTP
                                        </button>
                                    </div>

                                    <div className="form-group text-center">
                                        <p>Have an account already ? <a href="/login" className="link d-block d-sm-inline-block text-sm-left text-danger text-center">Login</a>
                                        </p>
                                    </div>
                                    <div className="text-center">
                                        <div className="modal-divider">
                                            <span>Or login via</span>
                                        </div>
                                        {/* <div className="login-options social-login">
                                            <a href="" className="btn connect-fb">
                                                <i className="ti-facebook"></i>Facebook</a>
                                        </div> */}
                                        {/* <div className="facebook-login mt-2"
                                            style={{ cursor: 'pointer' }}
                                            // onClick={facebookLogin}
                                            >
                                            <a className="btn" style={{ border: 'none', color: 'white' }}>
                                                <img src={FacebookLogo} alt="Facebook Logo" className="google-logo" />
                                                Login with Facebook
                                            </a>
                                        </div> */}
                                        <div className="google-login mt-2"
                                            style={{ cursor: 'pointer' }}
                                            onClick={googleLogin}>
                                            <a className="btn" style={{ border: 'none' }}>
                                                <img src={GoogleLogo} alt="Google Logo" className="google-logo" />
                                                Login with Google
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </div>
    </>
}

export default RegisterPage;



