import { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropertyApiService from "../../../../data/api/services/property/property-api-service";
import Select from 'react-select';
import { FaSearch } from 'react-icons/fa';
import ProfessionalPageApiService from "../../../../data/api/services/professional-page/professional-page-api-service";
import ValidationError from "../../../../common/validation-error";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import propertyApiService from "../../../../data/api/services/property/property-api-service";
import FormFieldError from "../../../../components/form-field-error/form-field-error";
import { Spinner } from "react-bootstrap";
import ProfessionalPageServiceImage from '../../../../assets/professional-page-4.jpeg';

const EditProfessionalPageService = ({ professionalPageId, professionalPageFormData, onPreviousCallback, onNextCallback }) => {

    const tabNumber: number = 4;
    const [showNext, setShowNext] = useState(false);
    const [selectedProfessionTypeIdStr, setselectedProfessionTypeIdStr] = useState<any>([]);
    const professionalPAgeApiservice = new ProfessionalPageApiService();
    const [categoryData, setCategoryData] = useState([]);
    const [serviceCategorySearchLoading, setServiceCategorySearchLoading] = useState(false);
    const [serviceCategoryData, setServiceCategoryData] = useState([]);
    const [selectedServiceCategory, setselectedServiceCategory] = useState([]);
    const [dataaa, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredCategoryData, setFilteredCategoryData] = useState([]);
    const [locationValidationErrorMsg, setLocationValidationNameErrorMsg] = useState(null);
    const [nameValidationErrorMsg, setNameValidationErrorMsg] = useState(null);
    const [nickNameValidationErrorMsg, setNickValidationNameErrorMsg] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [asyncTypeaheadKey, setAsyncTypeaheadKey] = useState(0);

    const handleSelectedProfessionType = (spt: any) => {
        setselectedServiceCategory([]);
        setselectedProfessionTypeIdStr(spt);
        getBusiness(spt.id);
        setAsyncTypeaheadKey((prevKey) => prevKey + 1);
    }

    const preview = () => {
        onPreviousCallback(tabNumber - 1);
        setShowNext(false);

    }
    const searchCategory = async () => {
        let data = await professionalPAgeApiservice.getAllProfessionType();
        console.log(data);
        setCategoryData(data);
    };

    const getBusiness = async (professionId) => {
        try {
            let res = await professionalPAgeApiservice.getProfessionalPageServicesData(professionId);
            setServiceCategoryData(res)
        } catch (error) {
            console.log(error);
        }
    };

    const filterOption = (option, inputValue) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    };

    const validateAndSaveCategoryFormData = () => {
        try {
            validateCategoryFormData();
            if (professionalPageFormData.has('professionTypeIdStr')) {
                professionalPageFormData.delete('professionTypeIdStr');
            }
            professionalPageFormData.append('professionTypeIdStr', selectedProfessionTypeIdStr.id);

            if (professionalPageFormData.has('pageServicesStr')) {
                professionalPageFormData.delete('pageServicesStr');
            }
            professionalPageFormData.append('pageServicesStr', JSON.stringify(selectedServiceCategory));
            onNextCallback((tabNumber + 1));
        } catch (error: any) {
            throw error;
        }
    }

    const validateCategoryFormData = (): void => {
        let errors: any[] = [];
        const selectedServiceCategoryData = JSON.stringify(selectedServiceCategory);
        if (!selectedProfessionTypeIdStr) {
            let error: any = {};
            error.validationCode = "professionType";
            error.message = 'Profession Type required';
            errors.push(error);
        }
        if (selectedServiceCategoryData.length === 0) {
            let error: any = {};
            error.validationCode = "serviceCategory";
            error.message = 'Service Category data required';
            errors.push(error);
        }

        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }

    const highlightPageCategoryFormData = async () => {

        let formData: FormData = professionalPageFormData;
        console.log('data', dataaa);

        if (formData.get('professionTypeIdStr')) {
            const professionTypeId = formData.get('professionTypeIdStr').toString();
            const getProfessionalType = await professionalPAgeApiservice.getProfessionTypeById(professionTypeId);
            setselectedProfessionTypeIdStr({ id: professionTypeId, label: getProfessionalType.label });
            getBusiness(professionTypeId);
        }

        if (formData.has('pageServicesStr')) {
            let serviceCategoryData: any[] = JSON.parse(formData.get('pageServicesStr').toString());
            setselectedServiceCategory([...serviceCategoryData]);
        }
    }

    const onServiceCategorySelected = (selected: any) => {
        setselectedServiceCategory(selected);
    };

    const removeServiceCatgegory = (category: { id: any; }) => {
        setselectedServiceCategory((prevCategories: any[]) =>
            prevCategories.filter((preCategory) => preCategory.id !== category.id)
        );
    };

    const getTemplateData = async () => {
        let data = await professionalPAgeApiservice.getProfessionalPageTemplateData();
        // setServiceCategoryData(data.serviceCategoryData); 
    }

    const searchServiceCategories = (query) => {
        setServiceCategorySearchLoading(true);
        setTimeout(() => {
            // const filteredOptions = serviceCategoryData.filter((category) =>
            //     category.serviceName.toLowerCase().includes(query.toLowerCase())
            // );
            let filteredOptions;
            if (query.trim() === "") {
                filteredOptions = serviceCategoryData;
            } else {
                filteredOptions = serviceCategoryData.filter((category) =>
                    category.serviceName.toLowerCase().includes(query.toLowerCase())
                );
            }
            setFilteredCategoryData(filteredOptions);
            setServiceCategorySearchLoading(false);
        }, 1000);
    };

    const removeLocation = (location: { id: any; }) => {
        setselectedServiceCategory((prevLocations: any[]) =>
            prevLocations.filter((prevLocation) => prevLocation.id !== location.id)
        );
    };

    useEffect(() => {
        getTemplateData();
        highlightPageCategoryFormData();
        searchCategory();
    }, []);

    return <>
        <div className="container boder-sy  my-4">
            <div className="row justify-content-center">
                <div className="col-sm-1 col-lg-1text-center mt-5">
                    <span className=""> <ArrowBackIcon onClick={preview} /></span>
                </div>
                <div className="col-sm-5 col-lg-5 d-flex align-self-center">
                    <img src={ProfessionalPageServiceImage} className='h-100 w-100' alt="google-bussiness" ></img>
                </div>
                <div className="col-sm-7 col-lg-6 mt-3">
                    <section className="p-0 pt-4">
                        <div className="row">
                            <div className="col-sm-10 col-lg-12 d-flex LKfzVb mt-2">
                                <h3 className="text-center">Choose the category that fits your business best</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-10 offset-sm-1 mt-1">
                                <span className='fs-5'>this helps customers find you if they are looking for a business like yours.</span>
                            </div>
                        </div>
                        <div className="span-font mt-2 mb-2">
                            <label className=''>Your Profession</label>
                        </div>
                        <div className="row col-sm-12 mb-4">
                            <div className="col-sm-1">
                                <FaSearch />
                            </div>
                            <div className="col-sm-11 p-0" >
                                <Select
                                    className="custom-select"
                                    options={categoryData}
                                    value={selectedProfessionTypeIdStr}
                                    onChange={handleSelectedProfessionType}
                                    filterOption={filterOption}
                                    isSearchable
                                    placeholder="Search..."
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            color: 'black',
                                            background: 'white',
                                        })
                                    }}
                                />
                                <FormFieldError errorMessage={nameValidationErrorMsg}></FormFieldError>
                            </div>
                        </div>

                        <div className="span-font mt-2 mb-2">
                            <label className=''>What Service is your business based in ?</label>
                        </div>
                        <div>
                            <div className="col-sm-12">
                                <AsyncTypeahead
                                    className="custom-typeahead"
                                    id="service-category-search"
                                    key={asyncTypeaheadKey}
                                    labelKey="serviceName"
                                    onSearch={searchServiceCategories}
                                    onChange={onServiceCategorySelected}
                                    options={filteredCategoryData}
                                    isLoading={serviceCategorySearchLoading}
                                    placeholder="Search Service Categories (Optional)"
                                    multiple
                                    useCache={false}
                                    minLength={0}
                                    onFocus={() => searchServiceCategories('')}
                                />
                                <FormFieldError errorMessage={locationValidationErrorMsg}></FormFieldError>
                            </div>
                            <div className="selected-locations">
                                {selectedServiceCategory.length > 0 &&
                                    selectedServiceCategory.map((category) => (
                                        <div key={category.id} className="location-chip">
                                            {category.serviceName}
                                            <span className="remove-icon" onClick={() => removeServiceCatgegory(category)}>
                                                x
                                            </span>
                                        </div>
                                    ))}
                            </div>

                            <hr className="divider-line" />
                        </div>

                        <div className="text-end p-3">
                            <button disabled={processing} className="btn btn-success" onClick={validateAndSaveCategoryFormData} type="button">Next</button>
                            {(processing) && <Spinner animation="grow" variant="warning" />}
                        </div>
                        <div className="text-center">
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div>
        </div>
    </>

}
export default EditProfessionalPageService;