import StorageService from "../../../storage/storage-service";
import HttpClientWrapper from "../../http-client-wrapper";
import { AuthPayload } from "../auth/auth-payload";
import { CreatePropertyPayload, updateAmenities, UpdateLandPropertyInformation, UpdateModelPropertyInformation, UpdatePropertyInformation, UpdateRentPropertyInformation, updateLocationData, messagePayload, customPayload } from "./create-property-payload";

class PropertyApiService {
    
    [x: string]: any;

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getAllProperty = async (url:any) => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/property/'+url);
            console.log("PropertyApi getAllProperty() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }

    getProperty = async (id:any) => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/property/'+id);
            console.log("PropertyApi getProperty() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
    getLocation = async (id:any) => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/locations/'+id);
            console.log("LocationApi getLocation() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
    getAmenites = async (id:any) => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/property/'+id);
            console.log("PropertyApi getProperty() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
    
    saveProperty = async (formData:FormData) => {
        console.log("PropertyApiService regiaddPropertyster() start = " + JSON.stringify(formData));
        try {
            let data: any = await this.httpClientWrapper.postFormData('/v1/property', formData);
            console.log("PropertyApiService addProperty() response data " + JSON.stringify(data));
            console.log("PropertyApiService addProperty() end = " + JSON.stringify(formData));
            return data;
        } catch (error) {
            throw error;
        }
    }
  
    saveGeneral = async (formData:FormData) => {
        console.log("PropertyApiService regiaddPropertyster() start = " + JSON.stringify(formData));
        try {
            let data: any = await this.httpClientWrapper.postFormData('/v1/property/saveGeneralInfo/', formData);
            console.log("PropertyApiService addProperty() response data " + JSON.stringify(data));
            console.log("PropertyApiService addProperty() end = " + JSON.stringify(formData));
            return data;
        } catch (error) {
            throw error;
        }
    }
    updateGeneral = async (id:any, formData:any) => {
        console.log("PropertyApiService updateProprtty() start = " + JSON.stringify(formData));
        try {
            let data: any = await this.httpClientWrapper.putFormData('/v1/property/'+id+'/updateGeneralInfo/', formData);
            console.log("PropertyApiService updateProprtty() response data " + JSON.stringify(data));
            console.log("PropertyApiService updateProprtty() end = " + JSON.stringify(formData));
            return data;
        } catch (error) {
            throw error;
        }
    }

    saveLandGeneral = async (formData:FormData) => {
        console.log("PropertyApiService regiaddPropertyster() start = " + JSON.stringify(formData));
        try {
            let data: any = await this.httpClientWrapper.postFormData('/v1/property/saveLandGeneralInfo/', formData);
            console.log("PropertyApiService addProperty() response data " + JSON.stringify(data));
            console.log("PropertyApiService addProperty() end = " + JSON.stringify(formData));
            return data;
        } catch (error) {
            throw error;
        }
    }
    updateLandGeneral = async (id:any,formData:FormData) => {
        console.log("PropertyApiService regiaddPropertyster() start = " + JSON.stringify(formData));
        try {
            let data: any = await this.httpClientWrapper.putFormData('/v1/property/'+id+'/updateLandGeneralInfo/', formData);
            console.log("PropertyApiService addProperty() response data " + JSON.stringify(data));
            console.log("PropertyApiService addProperty() end = " + JSON.stringify(formData));
            return data;
        } catch (error) {
            throw error;
        }
    }

    updateProperty = async (id:any, formData:any) => {
        console.log("PropertyApiService updateProprtty() start = " + JSON.stringify(formData));
        try {
            let data: any = await this.httpClientWrapper.putFormData('/v1/property/'+id, formData);
            console.log("PropertyApiService updateProprtty() response data " + JSON.stringify(data));
            console.log("PropertyApiService updateProprtty() end = " + JSON.stringify(formData));
            return data;
        } catch (error) {
            throw error;
        }
    }

    deletePropertyImage = async (propertyId:any, id:any) => {
        try {
            let data:any = await this.httpClientWrapper.delete('/v1/property/'+propertyId+'/images/'+id);
            console.log("PropertyApi deletePropertyImage() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
    deletePropertyFile = async (propertyId:any, id:any) => {
        try {
            let data:any = await this.httpClientWrapper.delete('/v1/property/'+propertyId+'/planDocuments/'+id);
            console.log("PropertyApi deletePropertyImage() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
    deleteCoverImage = async (propertyId:any) => {
        try {
            let data:any = await this.httpClientWrapper.delete('/v1/property/deletedCoverImages/'+propertyId);
            console.log("PropertyApi deletePropertyImage() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
    deleteProperty = async (id:any) => {
        try {
            let data:any = await this.httpClientWrapper.delete('/v1/property/'+id);
            console.log("PropertyApi deleteProperty() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
    deletePropertyNearByFacility = async (propertyId:any, id:any) => {
        try {
            let data:any = await this.httpClientWrapper.delete('/v1/property/'+propertyId+'/nearbyFacility/'+id);
            console.log("PropertyApi deletePropertyNearByFacility() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
    
    updateLocation = async (propertyId:any, payload:updateLocationData) => {
        console.log("LocationApiService updateLocation() start = " + JSON.stringify(payload));
        try {
            let data: any = await this.httpClientWrapper.put('/v1/property/'+propertyId+'/updateLocation/',payload);
            console.log("PropertyApiService updateProprtty() response data " + JSON.stringify(data));
            console.log("PropertyApiService updateProprtty() end = " + JSON.stringify(payload));
            return data;
        } catch (error) {
            throw error;
        }
    }

    updateInformation = async (propertyId:any, formData:any) => {
        console.log("LocationApiService updateLocation() start = " + JSON.stringify(formData));
        try {
            let data: any = await this.httpClientWrapper.putFormData('/v1/property/'+propertyId+'/updateInformation', formData);
            console.log("PropertyApiService updateInformation() response data " + JSON.stringify(data));
            console.log("PropertyApiService updateInformation() end = " + JSON.stringify(formData));
            return data;
        } catch (error) {
            throw error;
        }
    }
    updateRentInformation = async (propertyId:any, payload:UpdateRentPropertyInformation) => {
        console.log("LocationApiService updateLocation() start = " + JSON.stringify(payload));
        try {
            let data: any = await this.httpClientWrapper.put('/v1/property/'+propertyId+'/updateRentInformation/', payload);
            console.log("PropertyApiService updateInformation() response data " + JSON.stringify(data));
            console.log("PropertyApiService updateInformation() end = " + JSON.stringify(payload));
            return data;
        } catch (error) {
            throw error;
        }
    }
    updateLandInformation = async (propertyId:any, payload:UpdateLandPropertyInformation) => {
        console.log("InformationApiService updateLandInformation() start = " + JSON.stringify(payload));
        try {
            let data: any = await this.httpClientWrapper.put('/v1/property/'+propertyId+'/updateLandInformation/', payload);
            console.log("PropertyApiService updateLandInformation() response data " + JSON.stringify(data));
            console.log("PropertyApiService updateLandInformation() end = " + JSON.stringify(payload));
            return data;
        } catch (error) {
            throw error;
        }
    }
    updateModelInformation = async (propertyId:any, payload:UpdateModelPropertyInformation) => {
        console.log("InformationApiService updateModelInformation() start = " + JSON.stringify(payload));
        try {
            let data: any = await this.httpClientWrapper.put('/v1/property/'+propertyId+'/updateModelHouseInformation/', payload);
            console.log("PropertyApiService updateModelInformation() response data " + JSON.stringify(data));
            console.log("PropertyApiService updateModelInformation() end = " + JSON.stringify(payload));
            return data;
        } catch (error) {
            throw error;
        }
    }
    updateModelLocation = async (id:any, formData:any) => {
        console.log("LocationApiService updateModelLocation() start = " + JSON.stringify(formData));
        try {
            let data: any = await this.httpClientWrapper.putFormData('/v1/property/'+id+'/updateModelHouseLocation/', formData);
            console.log("PropertyApiService updateModelLocation() response data " + JSON.stringify(data));
            console.log("PropertyApiService updateModelLocation() end = " + JSON.stringify(formData));
            return data;
        } catch (error) {
            throw error;
        }
    }
    
    updateMedia = async (propertyId:any, formData:any) => {
        console.log("MediaApiService updateMedia() start = " + JSON.stringify(formData));
        try {
            let data: any = await this.httpClientWrapper.putFormData('/v1/property/'+propertyId+'/updateMedia/', formData);
            console.log("MediaApiService updateMedia() response data " + JSON.stringify(data));
            console.log("MediaApiService updateMedia() end = " + JSON.stringify(formData));
            return data;
        } catch (error) {
            throw error;
        }
    }

    updateAmenities = async (propertyId:any, payload:any) => {
        console.log("AmenitiesApiService updateAmenities() start = " + JSON.stringify(payload));
        try {
            let data: any = await this.httpClientWrapper.put('/v1/property/'+propertyId+'/updateAmenities', payload);
            console.log("AmenitiesApiService updateAmenities() response data " + JSON.stringify(data));
            console.log("AmenitiesApiService updateAmenities() end = " + JSON.stringify(payload));
            return data;
        } catch (error) {
            throw error;
        }
    }

    getTemplatesData = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/property/templates');
            console.log("PropertyApi getTemplatesData() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
    
    getLocationTemplatesData = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/property/getLocationTemplateData');
            console.log("PropertyApi getTemplatesData() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }

    getGeneralPropertyTemplatesData = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/property/getGeneralInfoTemplateData');
            console.log("PropertyApi getTemplatesData() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }

    getLandGeneralPropertyTemplatesData = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/property/getLandGeneralInfoTemplateData');
            console.log("PropertyApi getTemplatesData() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }

    getInformationTemplatesData = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/property/getSaleInformationTemplateData');
            console.log("PropertyApi getInformationTemplateData() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }

    getLandInformationTemplatesData = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/property/getLandInformationTemplateData');
            console.log("PropertyApi getLandInformationTemplateData() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }

    getRentInformationTemplatesData = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/property/getRentInformationTemplateData');
            console.log("PropertyApi getLandInformationTemplateData() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }

    getModelInformationTemplateData = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/property/getModelHouseInformationTemplateData');
            console.log("PropertyApi getModelInformationTemplateData() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }

    searchLocation =  async (keyword: any) => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/locations?keyword='+encodeURIComponent(keyword));
            console.log("LocationApiService searchLocation() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
    searchProfessional =  async (keyword: any) => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/professionalPages?keyword='+encodeURIComponent(keyword));
            console.log("LocationApiService searchLocation() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
    searchTag =  async (keyword: any) => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/professionalPagePostTags?keyword='+encodeURIComponent(keyword));
            console.log("LocationApiService searchLocation() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
    doMessage = async (payload: messagePayload) => {
        console.log("AuthApiService doLogin() start = " + JSON.stringify(payload));
        try {          
            let data: any = await this.httpClientWrapper.post('/v1/messages', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    createCustomPlan = async (payload: customPayload) => {
        console.log("AuthApiService doLogin() start = " + JSON.stringify(payload));
        try {          
            let data: any = await this.httpClientWrapper.post('/v1/customPlans', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

   

   
    searchTitle =  async (keyword: any) => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/addNewPlan?keyword='+encodeURIComponent(keyword));
            console.log("LocationApiService searchLocation() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
    getFAQ = async (id:any) => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/faqs/'+id+'/fetchAll');
            console.log("PropertyApi getProperty() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
    getMemberShipFAQ = async (id:any) => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/memberShipFaqs/'+id+'/fetchAll');
            console.log("PropertyApi getProperty() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
}

export default PropertyApiService;