import { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import "./otp-dialog.css";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import OTPPageLogo from '../../assets/otp-page-logo.png'
import AuthApiService from "../../data/api/services/auth/auth-api-service";
import SmsApiService from "../../data/api/services/sms/sms-api-service";

const OtpDialogBox = ({ registrationPayload, onNextCallback }) => {

    const tabNumber: number = 2;
    const authService: AuthApiService = new AuthApiService();
    const smsService : SmsApiService = new SmsApiService();
    const [showPopUp, setShowPopUp] = useState(false);
    const [mobileNumber, setMobileNumber] = useState(registrationPayload?.mobile);
    const [otp, setOtp] = useState(registrationPayload?.otp);
    const navigate = useNavigate();
    const [otpDigits, setOtpDigits] = useState(['', '', '', '']);
    const inputRefs = useRef([]);
    const handleShow = () => setShowPopUp(true);
    const handleHide = () => setShowPopUp(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [seconds, setSeconds] = useState(10);

    const handleOtpChange = (index, value) => {
        if (value.length <= 1 && /^[0-9]*$/.test(value)) {
            const newOtpDigits = [...otpDigits];
            newOtpDigits[index] = value;
            setOtpDigits(newOtpDigits);
            if (index < otpDigits.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const displayLastTwoDigits = (number) => {
        const length = number.length;
        if (length < 2) {
            return number;
        }
        const firstTwoDigits = number.substring(0, 2);
        const lastTwoDigits = number.substring(number.length - 2);
        const maskedNumber = `${firstTwoDigits}******${lastTwoDigits}`;
        return maskedNumber;
    };

    const handleClose = () => {
        setShowPopUp(false);
        // navigate(-1);
        onNextCallback((tabNumber - 1));
    };

    const handleVerifyOtp = () => {
        const enteredOtp = otpDigits.join('');
        if (enteredOtp === otp) {
            onNextCallback((tabNumber + 1));
            toast.success("OTP Verified Successfully", { containerId: 'TR' });
        } else {
            toast.error('Incorrect OTP. Please try again.', { containerId: 'TR' });
        }
    };

    const resendOtp = async () => {
        try {
            let data = await smsService.generateOtp(mobileNumber);
            setOtp(data.content);
            console.log(data.content);
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message, { containerId: 'TR' });
        }
    };

    useEffect(() => {
        let timer;
        if (isOtpSent && seconds > 0) {
            timer = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
        }
        if (seconds === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, seconds]);

    const handleResendOtp = () => {
        setIsOtpSent(true);
        setSeconds(10);
        resendOtp();
    };

    useEffect(() => {
        handleShow();
        setIsOtpSent(true);
        setSeconds(10);
    }, []);

    return <>
        {/* <div>
            <Header></Header>
        </div> */}
        <Modal show={showPopUp} onHide={handleClose} backdrop="static">
            <div className="d-flex justify-content-end">
                <button className="btn text-danger" onClick={handleClose}> <HighlightOffIcon /></button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <div className="card card-ui px-3">
                    <div className="text-center">
                        {/* <img src="./veeduapp-fevicon.png" width="50px" alt="" /> */}
                        <img src={OTPPageLogo} width="50px" alt="" />
                    </div>
                    <h5 className="m-0 text-center">Mobile phone verification</h5>
                    <span className="mobile-text">
                        Enter the code we just sent on your mobile phone{' '}
                        <b className="text-danger">{displayLastTwoDigits(mobileNumber)}</b>
                    </span>
                    <div className="d-flex justify-content-center mt-5">
                        {otpDigits.map((digit, index) => (
                            <input
                                key={index}
                                type="text"
                                className="form-control text-input text-center"
                                value={digit}
                                maxLength={1}
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                ref={(input) => (inputRefs.current[index] = input)}
                            />
                        ))}
                    </div>
                    <div className="text-center my-3">
                        <span className="d-block mobile-text">
                            Not received an OTP?{' '}
                            <a
                                href="#"
                                className={`${isOtpSent ? 'text-muted' : 'text-danger'}`}
                                onClick={e => {
                                    e.preventDefault();
                                    if (!isOtpSent) handleResendOtp();
                                }}
                                style={{ pointerEvents: isOtpSent ? 'none' : 'auto', opacity: isOtpSent ? 0.5 : 1 }}
                            >
                                Resend OTP{isOtpSent && ` (${seconds}s)`}
                            </a>
                        </span>
                        <button
                            className="font-weight-bold rounded btn btn-danger cursor mt-2"
                            onClick={handleVerifyOtp}>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
        {/* <Footer /> */}
    </>
};
export default OtpDialogBox;