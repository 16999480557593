import React, { useState } from "react";
import AuthApiService from "../../data/api/services/auth/auth-api-service";
import "./forgot-password.css";
import { toast } from 'react-toastify';
import { Footer, Header } from "../../layouts";
import UserTypeWidget from "../../widgets/user-type/user-type-widget";
import { Modal } from "react-bootstrap";
import ForgetPasswordOTP from "./forget-password-otp";
import SmsApiService from "../../data/api/services/sms/sms-api-service";

const Forgotpassword = () => {

  const [userType, setUserType] = React.useState('');
  const [mobile, setmobile] = useState('');
  const [otpData, setOtpData] = useState("");
  const [mobileValidationError, setMobileNoValidationError] = useState(false);
  const authService: AuthApiService = new AuthApiService();
  const smsService : SmsApiService = new SmsApiService();
  const [userData, setUserData] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);

  const handleUserTypeChange = (userType: any) => {
    setUserType(userType);
  };

  const handleMobileNumberChange = (value: any) => {
    const formattedValue = value.replace(/\D/g, '').slice(0, 10);
    setmobile(formattedValue);
    setMobileNoValidationError(false);
  };

  const validateMobile = () => {
    let isFormValid = true;
    if (!mobile) {
      setMobileNoValidationError(true);
      isFormValid = false;
    }
    if (mobile) {
      if (mobile.length <= 9) {
        toast.error("Enter a Valid Mobile No. ", { containerId: 'TR' });
        isFormValid = false;
      }
    }
   
    return isFormValid;
  }

  const sendOtp = async () => {
    try {
      if (validateMobile()) {
        setLoading(true);
        findUserByMobile();
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, { containerId: 'TR' });
    }
  }

  const findUserByMobile = async () => {
    try {
      let url = `?mobileNo=${mobile}`;
      let data = await authService.fetchUserByMobile(url);
      setUserData(data.id);
      if (data) {
        console.log('dataaaaaas=>', data);
        let otp = await smsService.generateForgotPasswordOtp(data.mobile,data.firstName);
        setOtpData(otp);
        setLoading(false);
        setShow(true);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, { containerId: 'TR' });
    }
  };

  return (
    <>
      <div>
        <Header />
      </div>
      <div className="d-flex justify-content-center align-items-center my-5 pt-5">
        <div className="card h-80 m-3 col-lg-4">
          <div className="card-body">
            <h5 className="card-title login-titles">Forget Password</h5>
            <form className="row g-3">
              <div className="col-md-12">
                <label>Enter Your Phone Number</label>
                <input
                  className={`input-with-icon form-control  ${mobileValidationError ? 'is-invalid' : ''}`} type="text"
                  value={mobile} onChange={e => { handleMobileNumberChange(e.target.value) }} placeholder="Phone/ WhatsApp number" name="password_confirmation" />
                {mobileValidationError && <div className="invalid-feedback">Mobile No. required</div>}
              </div>
              {/* <div className="col-md-12">
                <div className="row d-flex justify-content-start">
                  <div >
                    <UserTypeWidget onUserTypeChangeListener={handleUserTypeChange}></UserTypeWidget>
                  </div>
                </div>
              </div> */}
            </form>
            <div className="form-group" style={{ position: 'relative' }}>
              {loading && (
                <div className="d-flex justify-content-center align-items-center"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    zIndex: 2
                  }}>
                  <div className="loading">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
              <button
                type="button"
                className="btn btn-md w-100 p-3 rounded login-btn mt-3"
                onClick={sendOtp}>
                Generate Otp
                {/* <span style={{
                position: 'relative',
                top: '8px',
                right: '37px',
              }}> */}
                {/* {processing && <Spinner animation="grow" variant="warning" />} */}
                {/* </span> */}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal size="lg" show={show} onHide={handleClose} className="d-flex justify-content-center align-items-center">
        <ForgetPasswordOTP mobileNo={mobile} otpData={otpData} userData={userData} handleClose={handleClose} />
      </Modal>
    </>
  );
};
export default Forgotpassword;