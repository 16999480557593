import { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./view-plan-status.css";
import { Footer, Header } from "../../../layouts";
import { useNavigate } from 'react-router-dom';
import PropertyLoanApiService from "../../../data/api/services/property-loan/property-loan-api-service";
import { toast } from "react-toastify";

const ViewPlanStatus = () => {

    const [showPopUp, setShowPopUp] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const [countryCode, setcountryCode] = useState("");
    const [countryData, setCountryData] = useState<any>([]);
    const propertyLoanApiService = new PropertyLoanApiService();
    const [mobileNoValidationError, setMobileNoValidationError] = useState(false);
    const [countryValidationError, setCountryValidationError] = useState(false);
    const [otpValidationError, setOtpValidationError] = useState(false);
    const [verifyProcess, setVerifyProcess] = useState(false);
    const [sendOtpProcess, setSendOtpProcess] = useState(false);
    const [timer, setTimer] = useState(30);
    const [generatedOtp, setGeneratedOtp] = useState('');
    const [data, setData] = useState<any>({});


    const handleShow = () => setShowPopUp(true);
    const handleHide = () => setShowPopUp(false);


    const handleCountryChange = (code: any) => {
        setcountryCode(code);
        setCountryValidationError(false);
    };

    const handleClose = () => {
        setShowPopUp(false);
        navigate(-1);
    };

    const handleMobileNumberChange = (value: any) => {
        const formattedValue = value.replace(/\D/g, '').slice(0, 10);
        setMobileNumber(formattedValue);
        setMobileNoValidationError(false);
    };
    const handleEnteredOtp = (value: any) => {
        setOtp(value);
        setOtpValidationError(false);
    };
    const handleVerifyOtp = () => {
        if (validateOtp()) {
            setVerifyProcess(true);
            setOtpValidationError(false);
            if (generatedOtp == otp) {
                toast.success("OTP Verified Successfully", { containerId: 'TR' });
                setVerifyProcess(false);
                handleHide();
            } else {
                toast.error("Invalid OTP", { containerId: 'TR' });
                setVerifyProcess(false);
            }
        }

    }

    const getPropertyLoanByMobileNo = async () => {
        try {
            let res = await propertyLoanApiService.getPropertyLoanByMobileNo(mobileNumber);
            setGeneratedOtp(await propertyLoanApiService.generateOtp(countryCode + mobileNumber));
            toast.success("OTP send Successfully", { containerId: 'TR' });
            setData(res);
            console.log('getPropertyLoanByMobileNo', res);
        } catch (err: any) {
            setSendOtpProcess(false);
            toast.error(err.response.data.message, { containerId: 'TR' });
        }
    }

    const handleSendOtp = async () => {
        if (validateMobile()) {
            if (mobileNumber.length <= 9) {
                toast.error("Enter a Valid Mobile No. ", { containerId: 'TR' });
            } else {
                try {
                    setSendOtpProcess(true);
                    setTimer(30);
                    getPropertyLoanByMobileNo();
                } catch (err: any) {
                    toast.error(err.message, { containerId: 'TR' });
                }
            }
        }
    }

    const validateMobile = () => {
        let isFormValid = true;

        if (!countryCode) {
            setCountryValidationError(true);
            isFormValid = false;
        }
        if (!mobileNumber) {
            setMobileNoValidationError(true);
            isFormValid = false;
        }
        return isFormValid;
    }

    const validateOtp = () => {
        let isFormValid = true;
        if (!otp) {
            setOtpValidationError(true);
            isFormValid = false;
        }
        return isFormValid;
    }


    const getTemplatesData = async () => {
        try {
            let res = await propertyLoanApiService.getPropertyLoanTemplatesData();
            console.log(res);
            setCountryData(res.countryData);
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }
    
    useEffect(() => {
        let intervalId = null;

        if (timer > 0) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [timer]);

    useEffect(() => {
        handleShow();
        getTemplatesData();

    }, []);

    return <>
        <div>
            <Header></Header>
        </div>
        <Modal size="lg" show={showPopUp} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Verify Your Mobile First</Modal.Title>
            </Modal.Header>
            <div >
                <div className="modal-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-2 mt-2">
                                    <label>Mobile No.</label>
                                </div>
                                <div className="col-sm-2">
                                    <select className={`input-value form-control ${countryValidationError ? 'is-invalid' : ''}`}
                                        value={countryCode}
                                        onChange={(e) => handleCountryChange(e.target.value)} placeholder="select" >
                                        <option value="">select</option>
                                        {countryData.map((cData: any, index: any) => (
                                            <option key={cData.id} value={cData.countryCode}>{cData.countryCode}</option>
                                        ))}
                                    </select>
                                    {countryValidationError && <div className="invalid-feedback">Country code required</div>}
                                </div>
                                <div className="col-sm-4">
                                    <input type="number" className={`input-value form-control ${mobileNoValidationError ? 'is-invalid' : ''}`}
                                        value={mobileNumber}
                                        onChange={e => { handleMobileNumberChange(e.target.value) }} placeholder="Phone number" name="password_confirmation" />
                                    {mobileNoValidationError && <div className="invalid-feedback">Mobile No. required</div>}
                                </div>
                                <div className="col-sm-3 mt-1">
                                    {!sendOtpProcess && <button className="btn btn-success" type="button"
                                        onClick={handleSendOtp}>Send OTP</button>}
                                    {sendOtpProcess && <button className="btn btn-success" type="button"
                                        onClick={handleSendOtp} disabled={timer > 0}>ReSend OTP</button>
                                    }

                                    <div className="col-sm- mt-3">
                                        {sendOtpProcess && <label> Resend OTP in {timer}s </label>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {sendOtpProcess && <div className="modal-body">
                <div className="row">
                    <div className="row">
                        <div className="col-sm-2 mt-2">
                            <label>Enter OTP.</label>
                        </div>
                        <div className="col-sm-3">
                            <input type="number" value={otp}
                                className={`input-value form-control ${otpValidationError ? 'is-invalid' : ''}`}
                                onChange={e => { handleEnteredOtp(e.target.value) }} placeholder="OTP" />
                            {otpValidationError && <div className="invalid-feedback">Please Enter the OTP </div>}
                        </div>
                        <div className="col-sm-3 mt-1">
                            <button disabled={verifyProcess} className="btn btn-success" type="button" onClick={handleVerifyOtp}>Verify</button>
                        </div>
                    </div>
                </div>
            </div>}

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

        {/* <div className="bg-img-backround verflow-hidden">
            <div className="image-cover hero-banner mt-4" >
                <div className="container">

                    <div className="hero-search-wrap mt-5">
                        <div className="row p-3">
                            <div className="col-sm-6">
                                <h1>Your Plan Status </h1>
                            </div>
                            <div className="col-sm-6 mt-1">
                                {!data.loanHistoryStatus && (<h3>Plan : <span className="pending"> Pending ! </span></h3>)}
                                {data.loanHistoryStatus && (<h3>Loan Status : <span className="success"> {data.loanHistoryStatus} </span></h3>)}
                            </div>
                        </div>
                        <div className="hero-search-content side-form mt-5">
                            <div className="row detail_features">
                                <div className="col-sm-4"> {data.loanType && (<label>Loan Type : <span>  {data.loanType} </span></label>)}</div>
                                <div className="col-sm-4">{data.name && (<label>Name : <span>  {data.name} </span></label>)}</div>
                                <div className="col-sm-4">{data.whatsAppNumber && (<label>Mobile Number : <span>  {data.whatsAppNumber} </span></label>)}</div>
                                <div className="col-sm-4">{data.countryCode && (<label>Country Code : <span>  {data.countryCode} </span></label>)}</div>
                                <div className="col-sm-4">{data.email && (<label>Email : <span>  {data.email} </span></label>)}</div>
                                <div className="col-sm-4">{data.age && (<label>Age: <span>  {data.age} </span></label>)}</div>
                                <div className="col-sm-4"> {data.personType && (<label>User Type : <span>  {data.personType} </span></label>)}</div>
                                <div className="col-sm-4">{data.primaryBank && (<label>Primary Bank : <span>  {data.primaryBank} </span></label>)}</div>
                            
                            </div>

                        
                        </div>

                    </div>
                </div>
            </div>
        </div> */}
        {/* <Footer /> */}
    </>
};
export default ViewPlanStatus;