import StorageService from "../../../storage/storage-service";
import HttpClientWrapper from "../../http-client-wrapper";
import { AfterPaymentPayload, AuthPayload } from "./auth-payload";

class AuthApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    doLogin = async (payload: AuthPayload) => {
        console.log("AuthApiService doLogin() start = " + JSON.stringify(payload));
        try {
            if (StorageService.getToken() || StorageService.getToken != null) {
                window.localStorage.clear();
            }
            let data: any = await this.httpClientWrapper.post('/v1/auth', payload);
            if (data && data.jwtResponse.accessToken) {
                StorageService.setToken(data.jwtResponse.accessToken);
            }
            if (data && data.user) {
                StorageService.saveUserData(data.user);
            }
            console.log("AuthApiService doLogin() response data " + JSON.stringify(data));
            console.log("AuthApiService doLogin() end = " + JSON.stringify(payload));
            return data;
        } catch (error) {
            throw error;
        }
    }

    doCheckProxyUser = async () => {
        console.log("AuthApiService checkProxyUser() start = isProxyUser");
        try {
            let data: any = await this.httpClientWrapper.get('/v1/auth/checkProxyUser');
            console.log("PropertyApi checkProxyUser() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

    doProxyUserLogin = async (payload: any) => {
        console.log("AuthApiService doProxyUserLogin() start = isProxyUser");
        try {
            let data: any = await this.httpClientWrapper.post('/v1/auth/checkProxyUser', payload);
            console.log("AuthApiService doProxyUserLogin() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

    fetchUserByMobileNo = async (url: any) => {
        console.log("AuthApiService fetchUserByMobileNo() start = ", url);
        try {
            let data: any = await this.httpClientWrapper.get('/v1/auth/findUser' + url);
            console.log("AuthApiService fetchUserByMobileNo() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

   

    getUser = async (id: any) => {
        console.log("AuthApiService fetchUserByMobileNo() start = " + id);
        try {
            let data: any = await this.httpClientWrapper.get('/v1/persons/user/' + id);
            console.log("AuthApiService fetchUserByMobileNo() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

    updatePassword = async (id: any, payload: any) => {
        console.log("AuthApiService fetchUserByMobileNo() start = " + id);
        try {
            let data: any = await this.httpClientWrapper.put('/v1/persons/updateUser/' + id, payload);
            console.log("AuthApiService fetchUserByMobileNo() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

    updateSocialLogInUser = async (id: any, payload: any) => {
        console.log("AuthApiService fetchUserByMobileNo() start = " + id);
        try {
            let data: any = await this.httpClientWrapper.put('/v1/persons/updateSocialLogInUser/' + id, payload);
            console.log("AuthApiService fetchUserByMobileNo() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

    getSocialLogin = async (accessToken: any) => {
        try {
            if (StorageService.getToken() || StorageService.getToken != null) {
                window.localStorage.clear();
            }
            let data: any = await this.httpClientWrapper.get('/v1/auth/socialLogin' + accessToken);
            // if (data && data.jwtResponse.accessToken) {
            //     StorageService.setToken(data.jwtResponse.accessToken);
            // }
            // if (data && data.user) {
            //     StorageService.saveUserData(data.user);
            // }
            return data;
        } catch (error) {
            throw error;
        }
    }

    fetchUserByMobileNoEmailLogInTypeAndUserType = async (url: any) => {
        console.log("AuthApiService fetchUserByMobileNoEmailRoleAndUserType() start = ", url);
        try {
            let data: any = await this.httpClientWrapper.get('/v1/auth/findUserByMobileNoEmailRoleAndLogInType' + url);
            return data;
        } catch (error) {
            throw error;
        }
    }

    fetchUserByMobile = async (url: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/auth/findUserByMobile' + url);
            console.log("AuthApiService fetchUserByMobile() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

    afterPaymentLogIn = async (payload: AfterPaymentPayload) => {
        console.log("AuthApiService afterPaymentLogIn() start = " + JSON.stringify(payload));
        try {
            if (StorageService.getToken() || StorageService.getToken != null) {
                window.localStorage.clear();
            }
            let data: any = await this.httpClientWrapper.post('/v1/auth/afterPayment', payload);
            if (data && data.jwtResponse.accessToken) {
                StorageService.setToken(data.jwtResponse.accessToken);
            }
            if (data && data.user) {
                StorageService.saveUserData(data.user);
            }
            console.log("AuthApiService afterPaymentLogIn() response data " + JSON.stringify(data));
            console.log("AuthApiService afterPaymentLogIn() end = " + JSON.stringify(payload));
            return data;
        } catch (error) {
            throw error;
        }
    }
}
export default AuthApiService;