import HttpClientWrapper from "../../http-client-wrapper";
import { SaveLocationPayload } from "./location-payload";

class LocationApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getLocationById = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/locations/' + id);
            console.log("LocationApi getLocationById() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

    saveLocation = async (payload: SaveLocationPayload) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/locations', payload);
            console.log("LocationApi saveLocation() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

    searchPinCode = async (pinCode: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/pinCode/searchPinCode?pinCode=' + pinCode);
            console.log("LocationApiService searchPinCode() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

}
export default LocationApiService;