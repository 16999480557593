import StorageService from "../../../storage/storage-service";
import HttpClientWrapper from "../../http-client-wrapper";

class SmsApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    

    generateOtp = async (mobileNo) => {
        console.log("SmsApiService generateOtp() start = " + mobileNo);
        try {
            let data: any = await this.httpClientWrapper.get('/v1/sms/generateOtpForRegister/' + mobileNo);
            console.log("SmsApiService generateOtp() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }
    generateForgotPasswordOtp = async (mobileNo: string, name: string) => {
        console.log("SmsApiService generateForgotPasswordOtp() start = " + mobileNo + ", " + name);
        try {
            const data = await this.httpClientWrapper.get(`/v1/sms/forgotPassword/${mobileNo}/${name}`);
            console.log("SmsApiService generateForgotPasswordOtp() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            console.error("Error in generateForgotPasswordOtp:", error);
            throw error;
        }
    };
    
}
export default SmsApiService;