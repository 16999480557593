
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import AsyncTypeahead from "react-bootstrap-typeahead/types/components/AsyncTypeahead";
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import React from "react";
import TuneIcon from '@mui/icons-material/Tune';
import ProfilePic from "../../../assets/profile-pic.jpeg";
import StorageService from "../../../data/storage/storage-service";
import { useDispatch } from "react-redux";
import { Col, Figure, InputGroup, Modal, Nav, Row } from "react-bootstrap";
import map from "../../../assets/map.png";
import { faArrowRight, faBars, faCrosshairs, faMagnifyingGlass, faMap, faTag } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import FormFieldError from "../../../components/form-field-error/form-field-error";
import HomeProfessionalFollow from "../../home/components/home-professional-follow";
import HomeProfessionalFollowing from "../../home/components/home-professional-following";
import HomeProfessionalList from "../../home/components/home-professional-list";
import ProfessionLocationSearch from "../../home/components/profession-location-search";
import ProfessionTypeSearch from "../../home/components/profession-type-search";
import { PropertySearchAction } from "../../home/state/search-action";
import { Footer, Header } from "../../../layouts";
import building from "../../../assets/plotbuilding.png"
import buildup from "../../../assets/buildup.png"

import plot from "../../../assets/Plot.png"
import door from "../../../assets/door.png"
import user from "../../../assets/User.png"


import bhk from "../../../assets/bhk.png"
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import { toast } from "react-toastify";
import { PagePostTagFollowPayload, PagePostTagViewPayload } from "../../../data/api/services/professional-page/professional-page-payload";
import TagSearch from "../../profession/tag-search/tag-search";
import LoginPageDialog from "../../login/login-page-dialog";
import CustomPlan from '../custom-plan/custom-plan';



const HeaderCustom = (props: any) => {
    const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        console.log(event.target.value);
    };
    let navigate: any = useNavigate();
    
   return (
        <>
             <Header />
             <CustomPlan></CustomPlan>
        </>
    );






};
export default HeaderCustom;